<template>
	<div>
		<div class="osahan-home-page">
			<!-- body -->
			<div class="osahan-body">
				<!-- osahan vegan -->
				<div v-for="(category, key) in categories" :key="key">
					<div
						v-if="
							filteredRestaurants.filter(el =>
								el.tags.some(el => el.tag == category.tag)
							).length > 0
						"
						class="fresh-vegan pb-2"
					>
						<div class="d-flex align-items-center mt-2 mb-2 px-3">
							<h6
								class="m-0 font-weight-normal px-4 py-1 bg-magenta rounded-full text-white"
							>
								{{ category.label }}
							</h6>
							<hr class="w-full border-2 border-magenta-900" />
						</div>
						<div class="flex flex-wrap">
							<div
								v-for="(restaurant,
								key) in filteredRestaurants.filter(el =>
									el.tags.some(el => el.tag == category.tag)
								)"
								:key="key"
								class="w-full md:w-1/2 lg:w-1/3 p-2"
							>
								<div
									class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm"
								>
									<div class="list-card-osahan-2 p-0">
										<div
											class="member-plan absolute right-0 w-full bottom-0"
										>
											<div
												class="badge "
												style="background:rgb(0 0 0 / 62%); color:#fff; border-radius: 0px 0px 9px 9px; width: 100%;"
											>
												<h6
													class="text-white float-left"
												>
													{{ restaurant.name }}
												</h6>
												<div class="text-right">
													<span class="text-base">
														<i
															class="icofont-heart text-magenta mx-1"
														/>
														{{
															restaurant.favorit_count
														}}
													</span>
													<span class="text-base">
														<i
															class="icofont-minus-square text-magenta mx-1"
														/>
														{{
															restaurant.reviews_count
														}}
													</span>
												</div>
											</div>
										</div>
										<router-link
											:to="{
												name: 'restaurant-show',
												params: {
													restaurantId: restaurant.id
												},
												query: $route.query
											}"
											class="text-decoration-none text-dark"
										>
											<div
												class="w-full h-40 bg-cover rounded mb-0"
												:style="
													`background-image: url(${$constants.STORAGE_PATH}${restaurant.images[0]})`
												"
											/>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ApiGetRestaurants } from '@/tools/apiService';

export default {
	data: () => ({
		restaurants: [],
		filter: {
			keyword: '',
			listCitiesId: [],
			listTagsId: []
		}
	}),
	mounted: function() {
		this.getRestaurants();
		if (this.$route.query) this.checkQueryFilters();
	},
	watch: {
		$route: function() {
			if (this.$route.query) this.checkQueryFilters();
		}
	},
	methods: {
		checkQueryFilters: function() {
			this.filter = {
				keyword: this.$route.query.keyword,
				listCitiesId: this.$route.query.cities
					? this.$route.query.cities.split(',').map(el => Number(el))
					: [],
				listTagsId: this.$route.query.tags
					? this.$route.query.tags.split(',').map(el => Number(el))
					: []
			};
		},
		getRestaurants: function() {
			ApiGetRestaurants().then(response => {
				this.restaurants = response.data.restaurants.map(el => {
					el.images = JSON.parse(el.images);
					return el;
				});
				this.checkQueryFilters();
			});
		}
	},
	computed: {
		filteredRestaurants: function() {
			let restaurants = this.restaurants;

			if (
				this.filter.listCitiesId &&
				this.filter.listCitiesId.length > 0
			) {
				restaurants = restaurants.filter(el =>
					this.filter.listCitiesId.includes(el.cityId)
				);
			}
			if (this.filter.keyword) {
				restaurants = restaurants.filter(el =>
					el.name
						.toLowerCase()
						.startsWith(this.filter.keyword.toLowerCase())
				);
			}

			return restaurants;
		},
		categories: function() {
			let tags = this.restaurants.flatMap(el => el.tags);
			let uniqueTags = [...new Set(tags.map(el => el.tag))];
			let categories = uniqueTags.map(el =>
				tags.find(subEl => subEl.tag == el)
			);
			if (this.filter.listTagsId && this.filter.listTagsId.length > 0)
				return categories.filter(el =>
					this.filter.listTagsId.includes(el.id)
				);
			else return categories;
		}
	}
};
</script>
