var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"osahan-home-page"},[_c('div',{staticClass:"osahan-body"},_vm._l((_vm.categories),function(category,key){return _c('div',{key:key},[(
						_vm.filteredRestaurants.filter(function (el) { return el.tags.some(function (el) { return el.tag == category.tag; }); }
						).length > 0
					)?_c('div',{staticClass:"fresh-vegan pb-2"},[_c('div',{staticClass:"d-flex align-items-center mt-2 mb-2 px-3"},[_c('h6',{staticClass:"m-0 font-weight-normal px-4 py-1 bg-magenta rounded-full text-white"},[_vm._v(" "+_vm._s(category.label)+" ")]),_c('hr',{staticClass:"w-full border-2 border-magenta-900"})]),_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.filteredRestaurants.filter(function (el) { return el.tags.some(function (el) { return el.tag == category.tag; }); }
							)),function(restaurant,key){return _c('div',{key:key,staticClass:"w-full md:w-1/2 lg:w-1/3 p-2"},[_c('div',{staticClass:"list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm"},[_c('div',{staticClass:"list-card-osahan-2 p-0"},[_c('div',{staticClass:"member-plan absolute right-0 w-full bottom-0"},[_c('div',{staticClass:"badge ",staticStyle:{"background":"rgb(0 0 0 / 62%)","color":"#fff","border-radius":"0px 0px 9px 9px","width":"100%"}},[_c('h6',{staticClass:"text-white float-left"},[_vm._v(" "+_vm._s(restaurant.name)+" ")]),_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"text-base"},[_c('i',{staticClass:"icofont-heart text-magenta mx-1"}),_vm._v(" "+_vm._s(restaurant.favorit_count)+" ")]),_c('span',{staticClass:"text-base"},[_c('i',{staticClass:"icofont-minus-square text-magenta mx-1"}),_vm._v(" "+_vm._s(restaurant.reviews_count)+" ")])])])]),_c('router-link',{staticClass:"text-decoration-none text-dark",attrs:{"to":{
											name: 'restaurant-show',
											params: {
												restaurantId: restaurant.id
											},
											query: _vm.$route.query
										}}},[_c('div',{staticClass:"w-full h-40 bg-cover rounded mb-0",style:(("background-image: url(" + (_vm.$constants.STORAGE_PATH) + (restaurant.images[0]) + ")"))})])],1)])])}),0)]):_vm._e()])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }